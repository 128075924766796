import React, {useEffect, useState} from "react"
import {Container,Row,Col,Button} from 'react-bootstrap';
import { Link, navigate } from "gatsby";
import LeftNav from "../components/Common/leftNav";
import SEO from "../components/seo"

import {config} from '../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../queries/common_use_query";

const CreatePresentation = () => {
  const [ article_data, setArticleData ] = useState('');
  const { loading, error, data } = GET_ARTICLE('create-a-presentation');

  //console.log("data",data);
  useEffect(()=>{
    setArticleData(data && data.articles[0]);
  }, [data]);
  

  //console.log("article_data",article_data);

  return(
    <>
      <SEO title="CreatePresentation" description="CreatePresentation" />

      <div className="dashboard-wrapper">
        <LeftNav page_name="Create Presentation" />
        {/* sidebar */}

        <div className="dashboard-content">
        <Container>
            <div className="content-wrapper seller-wrapper">
              <div className="seller-section">
                <div className="seller-top">
                <h2>{article_data && article_data.Title}</h2>
                {article_data && article_data.Content && HTMLReactParser(article_data.Content)}
                </div>
                <Row>
                  {
                    article_data && article_data.Modules && article_data.Modules.map((module, index) => 
                      module.White_Box_Block && module.White_Box_Block.map((white_box) => {
                        return(
                          <Col xs={12} md={6}>
                            <div className="card-block">
                                <div className="card-content">
                                  <h3>{white_box.Title}</h3>
                                  <p>{white_box.Content}</p>
                                </div>
                                {/* card-content */}                          
                                <Link to={white_box.CTA_1_URL} className="btn btn-primary">{white_box.CTA_1_Label}</Link>
                            </div>
                        </Col>
                        )
                      })                      
                    )
                  }
                </Row>
              </div>
            </div>
        </Container>
        {/* container */}
      </div>
      {/* dashboard-content */}
      </div>
    </>
  )

}

export default CreatePresentation